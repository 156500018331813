import styled from '@emotion/styled'
import { ModifiableDiv } from 'components/styles/primitives/core/mods'
import { FlexMods, FlexParentBase } from 'components/styles/primitives/modifiers/flex'

/**
 * A style-free div, given all the super powers of display:flex;
 * Useful for distributing children without any fuss
 */
export const FlexContainer = styled(ModifiableDiv)`
	${FlexParentBase};
	${FlexMods.Parent};
`

/**
 * A good ole Box, given all the super powers of display:flex;
 */
export const FlexItem = styled(ModifiableDiv)`
	flex: 1 1 auto;
	${FlexMods.Child};
`
