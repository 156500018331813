import { ensureArray, SometimesArray } from 'lib/types/arrays'
import { css, SerializedStyles } from '@emotion/react'
import styled, { StyledComponent } from '@emotion/styled'

export interface Mods {
	modifiers?: SometimesArray<SerializedStyles | any>
}

export const Modifiable = ({ modifiers }: Mods) => ensureArray(modifiers).map(mod => css(mod))

export const makeModifiable = <T extends {}>(component: StyledComponent<T>) => styled(component)`
	${Modifiable}
`

export const ModifiableDiv = styled.div<Mods>`
	${Modifiable};
`
