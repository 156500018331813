import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'shared/layout/Container'
import { COLORS } from 'parameters/index'
import { Divider } from 'components/elements/Divider'

interface Props {
	background?: string
	divider?: boolean
	mobilePadding?: string
	isAffiliate?: boolean
}

const PageSection: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	background,
	divider = true,
	mobilePadding,
	isAffiliate
}): JSX.Element => (
	<Wrapper background={background} divider={divider} mobilePadding={mobilePadding} isAffiliate={isAffiliate}>
		<Container>{children}</Container>
		{divider && <Divider padding="60px 0 0 0" />}
	</Wrapper>
)

export default PageSection

interface StyleProps {
	background?: string
	divider?: boolean
	mobilePadding?: string
	isAffiliate?: boolean
}

const Wrapper = styled.div<StyleProps>`
	padding: ${({ divider }): string => (divider ? '60px 0 0 0' : '60px 0')};
	margin-top: ${({ isAffiliate }): string => (isAffiliate ? '60px' : '0')};
	background: ${({ background }): string => (background ? background : COLORS.WHITE)};

	@media only screen and (max-width: 768px) {
		padding: ${({ mobilePadding }): string | undefined => mobilePadding};
	}
`
