import React from 'react'
import styled from '@emotion/styled'
import { COLORS } from 'parameters/index'
import type { IPartner } from 'generated/types/contentful'
import { FlexContainer } from 'components/styles'
import { Container } from 'shared/layout/Container'
import { View } from 'reshaped/bundle'

interface Props {
	title?: JSX.Element
	description?: string
	partner?: IPartner
	iconSrc?: string
	className?: string
	style?: React.CSSProperties
}

const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({
	title,
	description,
	partner,
	iconSrc,
	className = '',
	style = {}
}): JSX.Element => (
	<HeaderWrapper className={className} style={style}>
		<Container>
			<View paddingBlock={8}>
				<FlexContainer>
					<HeaderText>{title}</HeaderText>
					{iconSrc && <HeaderIcon src={iconSrc} alt="" />}
				</FlexContainer>
				{partner && <PartnerSubtitle>{`Special Article for ${partner?.fields.name}`}</PartnerSubtitle>}
				{description && <Description>{description}</Description>}
			</View>
		</Container>
	</HeaderWrapper>
)

export default PageHeader

const HeaderWrapper = styled.div<{ padding?: string }>`
	width: 100%;
	padding: 40px 0;
	background-image: url(/images/contact-us-header.svg), linear-gradient(93deg, #592b86 7%, #3b1c5a 103%);
	background-repeat: no-repeat;
	background-position: top right;

	&.productHeader {
		padding: 40px 0 80px 0;
	}

	@media only screen and (max-width: 768px) {
		background-image: linear-gradient(93deg, #592b86 7%, #3b1c5a 103%);
		padding: 0;
		margin-top: -1px;
	}
`

const PartnerSubtitle = styled.div`
	text-align: right;
	display: flex;
	align-self: flex-end;
	color: ${COLORS.WHITE};
`

const HeaderText = styled.div`
	h1 {
		color: ${COLORS.WHITE};

		strong {
			color: ${COLORS.MALACHITE_GREEN};
		}
	}
`

const Description = styled.p`
	color: ${COLORS.WHITE};
	margin-top: 20px;
`

const HeaderIcon = styled.img`
	width: 100px;
	@media only screen and (max-width: 768px) {
		width: 68px;
	}
`
