/**
 * The standard interface for primitives
 */
export interface SpaceProp {
	space?: 'smaller' | 'small' | 'big' | 'bigger' | 'biggest' | 'base' | 0 | '0'
}

type StyleFn<T> = (props: T) => string

/**
 * Used in primitives to provide standard space without the pain of remembering
 * @param space
 */
export const space: StyleFn<SpaceProp> = ({ space = 'base' }) => {
	switch (space) {
		case 'base':
			return 'var(--space)'
		case 0:
		case '0':
			return '0'
		default:
			return `var(--space-${space})`
	}
}
