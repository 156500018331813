import { css } from '@emotion/react'
import { space, SpaceProp } from 'components/layout/primitives/utils'
import type { FlexChildProps, FlexParentProps } from './types'

export const FlexParentBase = css`
	display: flex;
	justify-content: space-between;
`

const FlexParentMod = (props: FlexParentProps) => {
	const { inline, direction, wrap, reverse, alignItems, justifyContent, wrapReverse, gap } = props
	return css`
		display: ${inline ? 'inline-flex' : 'flex'};
		flex-direction: ${direction}${reverse && !direction?.includes('reverse') ? '-reverse' : ''};
		flex-wrap: ${wrap && `wrap${wrapReverse ? '-reverse' : ''}`};
		align-content: ${wrap && props.alignContent};
		justify-content: ${justifyContent};
		align-items: ${alignItems};
		gap: ${gap};
	`
}

const FlexChildMod = ({ grow, shrink, basis, alignSelf, order, justifySelf }: FlexChildProps) => css`
	flex-grow: ${grow};
	flex-shrink: ${shrink};
	flex-basis: ${basis};
	align-self: ${alignSelf};
	justify-self: ${justifySelf};
	order: ${order};
`

const StandardGapSize = (props: SpaceProp) => css`
	gap: ${space(props)};
`

export const FlexMods = {
	StandardGapSize,
	Parent: FlexParentMod,
	Child: FlexChildMod,
	IntrinsicallySized: FlexChildMod({ grow: 0, shrink: 0 }),
	...FlexChildMod,
	...FlexParentMod
}
