import React from 'react'
import styled from '@emotion/styled'
import { COLORS, PAGE_WIDTH } from 'parameters/index'

interface Props {
	padding?: string
	color?: string
}

/**
 * @deprecated Use HDivider and VDivider from primitives
 */
export const Divider: React.FC<React.PropsWithChildren<Props>> = ({ padding, color }): JSX.Element => (
	<DividerWrapper padding={padding} color={color}>
		<hr />
	</DividerWrapper>
)

interface StyleProps {
	padding?: string
	color?: string
}

const DividerWrapper = styled.div<StyleProps>`
	max-width: ${PAGE_WIDTH};
	margin: 0 auto;
	padding: ${({ padding }) => padding};

	hr {
		border: 0;
		border-bottom: ${({ color }): string => (color ? `1px solid ${color}` : `1px solid ${COLORS.SMOKE_WHITE}`)};
	}
`
