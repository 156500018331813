import React from 'react'

import { GetStaticProps } from 'next'
import Error from 'components/layout/Error'
import { PageProps } from 'types/page'
import { getSeoMetaObject } from 'lib/routing/MetaTags'
import { getPageDetails } from 'services/ContentfulService/hooks/pages'
import { StandardLayout } from 'shared/layout/StandardLayout'
import { ContentfulContainer } from 'stores/Contentful'
import { Resource } from 'services/ContentfulService/models/resource/resource'
import { removeUndefs } from 'lib/objects/removeUndefineds'

interface Props extends PageProps {
	resources: Resource[]
}

const ErrorPage = ({ metaTags, resources }: Props): JSX.Element => {
	return (
		<ContentfulContainer initialResources={resources}>
			<StandardLayout metaTags={metaTags}>
				<Error />
			</StandardLayout>
		</ContentfulContainer>
	)
}

export const getStaticProps: GetStaticProps = async () => {
	const page = await getPageDetails('/404')
	const metaTags = getSeoMetaObject('/404', page)
	return {
		props: {
			metaTags: removeUndefs(metaTags),
			resources: removeUndefs(page.resources)
		}
	}
}

export default ErrorPage
