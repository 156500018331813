/**
 * Allows both an array and singular values of a type.
 * Use {ensureArray} to use as an array in all cases
 * @link ensureArray
 */
export type SometimesArray<T> = T | Array<T>

/**
 * Wraps a value in an array if necessary
 * @param {SometimesArray<T>} value
 * @returns {Array<T>}
 */
export const ensureArray = <T>(value: SometimesArray<T>): Array<T> => {
	if (value === undefined) return []
	if (Array.isArray(value)) return value
	return [value]
}

export const generateValues = <T>(count: number, generator: () => T): T[] => {
	const array: T[] = []
	for (let i = count - 1; i > 0; i--) {
		array.push(generator())
	}
	return array
}
